import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import BootstrapFormControl from 'react-bootstrap/cjs/FormControl';
import Button from '../../layout/form/Button';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../store/selector/projectSelector';
import * as actions from '../../../store/action/projectAction';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from '../../layout/form/Table';
import common from '../../common';
import moment from 'moment';
import DatePicker from '../../layout/form/datePicker';
import SuccessModal from '../../layout/form/successModal';
import ImportProjectActivity from './ImportProjectActivity';


export class ProjectActivity extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            activityList:[],
            tempActivityList:[],
            initial:true,
            isDeleteActivity: false,
            isImportModalOpen: false
        };

      };

      static getDerivedStateFromProps(prevProps,prevState) {
        const { selectedProject } = prevProps;
        if(selectedProject && selectedProject.id && prevState.initial ){    
            var itemList = []

            if(selectedProject.projectActivities.length !== 0){
                for (let i = 0; i < selectedProject.projectActivities.length; i++) {
                    const element = selectedProject.projectActivities[i];

                   const newRow = element
                    element.operation="U"
                    
                    itemList.push(newRow)
                }
            }
            return {
                activityList: itemList,
                initial:false
            }
        }
        
        return null
    }

    componentDidUpdate(prevProps, prevState){
        const { 
            activityList, 
            isDeleteActivity, 
            tempActivityList,
        } = this.state;
        if(isDeleteActivity){
            const srvActivitiyList = activityList.filter(data => (data.operation === "U" || data.operation === "C"))
            const tempItemList = activityList.filter(data => (data.id !== null ))
            this.setState({
                activityList:srvActivitiyList, 
                isDeleteActivity:false, 
                tempActivityList:tempActivityList.concat(tempItemList)
            })
        }

        if (this.props.projectActivitySuccess !== null){
            setTimeout(
                () => this.successModalClose(), 
                1000
            );
        }
    }

    uploadTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Import
        </Tooltip>
    );

    addNewRow = () => {
        var { activityList } = this.state;
        var { selectedProject, project } = this.props;
        var newRow = {
            description:"",
            startDate: selectedProject.planStartDate ? selectedProject.planStartDate : project.planStartDate ? project.planStartDate : null,
            endDate: selectedProject.planEndDate ? selectedProject.planEndDate : project.planEndDate ? project.planEndDate : null,
            operation: "C",
            id: 0
        }
        activityList.push(newRow)
        this.setState({activityList})
    }

    handleDescription = (e,rowData) => {
        const {activityList} = this.state;
        const newRow = {
            description:  e.target.value,
            startDate: rowData.original.startDate,
            endDate: rowData.original.endDate,
            id: rowData.original.id,
            operation: rowData.original.id > 0 ? "U" : "C"
        }
        
        // setTimeout(
            activityList[rowData.index] = newRow
            this.setState({
                activityList,
                isChangeData: true
            })
            // 500
        // );
    }

    handleDateChange = (date,rowData, id) => {
        const {activityList} = this.state;
        if(id === 'startDate'){
            const newRow = {
                description:  rowData.original.description,
                startDate: date === null ? null : moment(date).format(common.dateFormat),
                endDate: rowData.original.endDate,
                id: rowData.original.id,
                operation: rowData.original.id > 0 ? "U" : "C"
            }
            activityList[rowData.index] = newRow
            this.setState({
                activityList,
                isChangeData: true
            })
        }
        else if(id === 'endDate'){
            const newRow = {
                description:  rowData.original.description,
                startDate: rowData.original.startDate,
                endDate: date === null ? null : moment(date).format(common.dateFormat),
                id: rowData.original.id,
                operation: rowData.original.id > 0 ? "U" : "C"
            }
            activityList[rowData.index] = newRow
            this.setState({
                activityList,
                isChangeData: true
            })
        }
    }

    handleDelete = (rowData) => {
        var { activityList } = this.state;
        const newRow = {
            description:  rowData.original.description,
            startDate: rowData.original.startDate,
            endDate: rowData.original.endDate,
            id: rowData.original.id > 0 ? rowData.original.id : null,
            operation: "D"
        }
        activityList[rowData.index] = newRow
        this.setState({
            activityList, 
            isDeleteActivity:true,
            isChangeData: rowData.original.id > 0 ? true : false,
        })
    }

    handleImportModal = () => {
        this.setState({
            isImportModalOpen: true
        })
    }

    handleImportModalClose = () => {
        this.setState({
            isImportModalOpen: false
        })
    }

    handleSaveAddNew = (e) => {
        console.log(e)
    }

    successModalClose = () => {
        const { selectedProjectId } = this.props;
        this.setState({
            initial:true,
            tempActivityList:[],
            isImportModalOpen: false
        })
        this.props.dispatch(actions.loadSelectedProjectRequest(selectedProjectId))
        this.props.dispatch(actions.sendProjectActivitySuccess(null))
    }

    render() {
        const {
            handleClose,
            handleSaveAddNew,
            handleTab,
            modifyProject,
            // isUpdate,
            // selectedProjectId,
            projectActivitySuccess,
            handleSaveImportProjectActivities
        } = this.props;

        const columns = [{
            Header: 'External Id',
            accessor: 'externalId',
            Cell: (rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId={rowData.cell.row.original.externalId}
                        type="text"
                        defaultValue={rowData.cell.row.original.externalId}
                        size="sm"
                        disabled
                    />
                </div>
            ),
        },{
            Header: 'Description',
            accessor: 'description',
            Cell:(rowData) =>(
                <div>
                    <BootstrapFormControl 
                        controlId={rowData.cell.row.original.description}
                        type="text"
                        defaultValue={rowData.cell.row.original.description}
                        size="sm"
                        onBlur={(e)=>this.handleDescription(e,rowData.cell.row)}
                        disabled={!modifyProject}
                        maxLength={30}
                    />
                </div>
              ),    
          },{
            Header: 'Start Date',
            accessor: 'startDate',
            width:'15%',
            Cell:(rowData) =>(
                <div style={{margin:'-10px 0'}}>
                    <DatePicker
                        // text="Start Date"
                        disabled={!modifyProject}
                        controlId={rowData.cell.row.original.startDate}
                        onChange={(e) => this.handleDateChange(e,rowData.cell.row,"startDate")}
                        value={rowData.cell.row.original.startDate === null ? new Date() : new Date(rowData.cell.row.original.startDate)}
                    />
                </div>
              ),    
          },{
            Header: 'End Date',
            accessor: 'endDate',
            width:'15%',
            Cell:(rowData) =>(
                <div style={{margin:'-10px 0'}}>
                    <DatePicker
                        // text="End Date"
                        disabled={!modifyProject}
                        controlId={rowData.cell.row.original.endDate}
                        onChange={(e) => this.handleDateChange(e,rowData.cell.row,"endDate")}
                        value={rowData.cell.row.original.endDate === null ? new Date() : new Date(rowData.cell.row.original.endDate)}
                    />
                </div>
              ),    
          },{
            Header:'Action',
            width:'8%',
            Cell:((rowData)=>(
                <div>
                    {<span style={{display:'flex',justifyContent:'center'}}>
                        <span onClick={modifyProject ? ()=>this.handleDelete(rowData.cell.row) : ''}><ui5-icon class="samples-margin" name="delete" id={modifyProject ? "table-icon": "table-icon-disabled"}></ui5-icon></span>
                    </span>}
                </div>
            )),
            accessor:'action'
          }]

        return (
            <div>
                {(projectActivitySuccess) && 
                <SuccessModal
                    show={true} 
                    massage={projectActivitySuccess}
                    handleClose={this.successModalClose}
                />}
            <div className='content-body'>
                <div className="filter-header">
                    <div className="header-pages">
                        <span>Project </span>
                    </div>
                    <div className="btn-close" onClick={handleClose} >
                        <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                    </div>
                </div>
                <div className="content-header" style={{margin:'-20px 0 15px 0'}}>
                    <div className="header-pages">
                        <span style={{fontSize:'14px',color:'#4278b7'}} onClick={() => handleTab('projectDetails',this.state)}><ui5-icon class="samples-margin" name="header" id="title-icon"></ui5-icon> Header</span>
                        <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectMilestone', this.state)}><ui5-icon class="samples-margin" name="add-coursebook" id="title-icon"></ui5-icon> Milestone</span>
                        <span style={{fontSize:'14px',color:'#4278b7'}} onClick={() => handleTab('projectActivities',this.state)}><ui5-icon class="samples-margin" name="multiselect-all" id="title-icon"></ui5-icon> Activities</span>
                        <span style={{fontSize:'14px',color:'#4278b7'}} onClick={() => handleTab('projectAssignments',this.state)}><ui5-icon class="samples-margin" name="add-employee" id="title-icon"></ui5-icon> Assignments</span>
                        <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectApprovers', this.state)}><ui5-icon class="samples-margin" name="kpi-managing-my-area" id="title-icon"></ui5-icon> Approvers</span>
                    </div>
                </div>
                {
                    this.state.isImportModalOpen ? 
                        <ImportProjectActivity
                            show={this.state.isImportModalOpen}
                            handleModalClose={this.handleImportModalClose}
                            handleSaveAddNew={handleSaveImportProjectActivities}
                        /> 
                        : ''
                }
                <div className="profile-content">
                    <div className="page_header">
                        <div className="page_header-title">
                            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
                                <div><span>Activities</span></div>
                                <div className="header-btn">
                                    <div style={{ display: 'flex' }}>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={this.uploadTooltip}
                                        >
                                            <span className={modifyProject ? "header-icon" : "header-icon-disabled"} onClick={modifyProject ? () => this.handleImportModal() : ''}>
                                                <ui5-icon class="samples-margin" name="upload-to-cloud" id="header-icon"></ui5-icon>
                                            </span>
                                        </OverlayTrigger>
                                    </div>
                                </div>    
                            </div>
                        </div>
                        <div style={{marginBottom:'10px'}}>
                            <Table
                                columns={columns}
                                data={this.state.activityList}
                            />
                            <div className={modifyProject ? "add-icon" : "add-icon-disabled"}>
                                <ui5-icon class="samples-margin" name="sys-add" id="add-activity" onClick={modifyProject ? () => this.addNewRow() : ''}></ui5-icon>
                            </div>
                            
                        </div>
                        <div style={{display:'flex', justifyContent:'flex-end'}}>
                            <Button
                                variant="outline-primary" 
                                text ='Save'
                                type="submit"
                                onClick={modifyProject ?  () => handleSaveAddNew(this.state) : ''}
                                icon='save'
                                disabled = {!modifyProject}
                            />
                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

ProjectActivity.propTypes = {
    isModalOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
    selectedProject: selectors.makeSelectSelectedProjectSuccess(),
    projectActivitySuccess: selectors.makeSelectProjectActivitySuccess(),
});
  
function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
}
  
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default (compose(withConnect)(ProjectActivity));