import { fromJS } from 'immutable';
import {
  LOAD_APP_BILLING_TERMS_SUCCESS,
  LOAD_APP_PACKAGE_SUCCESS,
  LOAD_APP_UOM_SUCCESS,
  LOAD_COUNTRY_SUCCESS,
  LOAD_OBJECT_STATUS_SUCCESS,
  LOAD_PUBLIC_CALENDAR_SUCCESS,
  LOAD_TENANT_SUCCESS,
  LOAD_TENANT_USER_SUCCESS,
  LOAD_UOM_SUCCESS,
  SAVE_COMMON_SEARCH_SUCCESS,
  SAVE_USER_ACCESS,
} from '../constant/constant';

export const initialState = fromJS({
  objectStatusList: [],
  uomList: [],
  countryList: [],
  tenant: {},
  tenantUser: {},
  userAccess: {},
  commonSearchData: {},
  billingTermsList: [],
  appPackageList: [],
  publicCalendar: [],
  appUomList: [],
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_OBJECT_STATUS_SUCCESS:
      return state.set('objectStatusList', action.payload)
    case LOAD_UOM_SUCCESS:
      return state.set('uomList', action.payload)
    case LOAD_COUNTRY_SUCCESS:
      return state.set('countryList', action.payload)
    case LOAD_TENANT_SUCCESS:
      return state.set('tenant', action.payload)
    case LOAD_TENANT_USER_SUCCESS:
      return state.set('tenantUser', action.payload)
    case SAVE_USER_ACCESS:
      return state.set('userAccess', action.payload)
    case SAVE_COMMON_SEARCH_SUCCESS:
      return state.set('commonSearchData', action.payload)
    case LOAD_APP_BILLING_TERMS_SUCCESS:
      return state.set('billingTermsList', action.payload)
    case LOAD_APP_PACKAGE_SUCCESS:
      return state.set('appPackageList', action.payload)
    case LOAD_PUBLIC_CALENDAR_SUCCESS:
      return state.set('publicCalendar', action.payload)
    case LOAD_APP_UOM_SUCCESS:
      return state.set('appUomList', action.payload)
    default:
      return state;
  }
}

export default appReducer;
