import { put, call } from 'redux-saga/effects';
import {
  addNewProjectRequest,
  getProjectList,
  updateProjectRequest,
  deleteProjectRequest,
  getSelectedProjectRequest,
  getSummarisedProjectList,
  getProjectAllocationTypeRequest,
  getProjectListbyStatus,
  getProjectTypeRequest,
  getProjectBillingTypeRequest,
  importProjectActivitiesRequest,
  getProjectTimeInputTypeRequest
} from '../service/projectService';
import * as actions from '../../store/action/projectAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* sendAddNewProjectRequest(data) {
  yield put(actions.loadProjectListError(null))
  try {
    const response = yield call(addNewProjectRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 201) {
      const id = response.data.result.id
      yield put(actions.loadSelectedProjectRequest(id))
      const massage = response.data.message
      yield put(actions.sendProjectSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadProjectListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadProjectListError(massage))
  }
}

export function* loadProjectListRequest(data) {
  yield put(actions.loadProjectListError(null))
  try {
    yield put(actions.loadProjectListLoading(true));
    const response = yield call(getProjectList, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const projectList = response.data.result
      yield put(actions.loadProjectListSuccess(projectList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadProjectListLoading(false));
      const massage = common.error
      yield put(actions.loadProjectListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadProjectListLoading(false));
  } catch (error) {
    yield put(actions.loadProjectListLoading(false));
    const massage = common.error
    yield put(actions.loadProjectListError(massage))
  }
}

export function* sendUpdateProjectRequest(data) {
  yield put(actions.loadProjectListError(null))
  try {
    const response = yield call(updateProjectRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.sendProjectSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadProjectListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadProjectListError(massage))
  }
}

export function* sendDeleteProjectRequest(data) {
  yield put(actions.loadProjectListError(null))
  try {
    yield put(actions.loadProjectListLoading(true));
    const response = yield call(deleteProjectRequest, data.payload);
    if (response && response.status && response.status === 200 && response.data && response.data.statusCode && response.data.statusCode === 404 && response.data.result.code) {
      var massage = common.massages.projectDeleteMassage
      const errorCode = common.deleteMassages.find(element => element.id === response.data.result.code)
      if (errorCode) {
        massage = errorCode.description
      }
      yield put(actions.loadProjectListError(massage))
    }
    else if(response && response.status && response.status === 200 && response.data &&  response.data.statusCode &&  response.data.statusCode === 200 ){
      const massage = response.data.message
      yield put(actions.sendProjectSuccess(massage)) 
    } 
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadProjectListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadProjectListLoading(false));
  } catch (error) {
    yield put(actions.loadProjectListLoading(false));
    const massage = common.error
    yield put(actions.loadProjectListError(massage))
  }
}

export function* selectedProjectRequest(data) {
  yield put(actions.loadProjectListError(null))
  try {
    yield put(actions.loadProjectListLoading(true));
    const response = yield call(getSelectedProjectRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const project = response.data
      yield put(actions.loadSelectedProjectSuccess(project))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadProjectListLoading(false));
      const massage = common.error
      yield put(actions.loadProjectListError(massage))
    }
    if (response && response.data && response.data.newAccessToken) {
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadProjectListLoading(false));
  } catch (error) {
    yield put(actions.loadProjectListLoading(false));
    const massage = common.error
    yield put(actions.loadProjectListError(massage))
  }
}

export function* sendUpdateProjectActivityRequest(data) {
  yield put(actions.loadProjectListError(null))
  try {
    const response = yield call(updateProjectRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.sendProjectActivitySuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadProjectListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadProjectListError(massage))
  }
}

export function* sendUpdateProjectAssignmentRequest(data) {
  yield put(actions.loadProjectListError(null))
  try {
    const response = yield call(updateProjectRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.sendProjectAssignmentSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadProjectListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadProjectListError(massage))
  }
}

export function* loadSummarisedProjectListRequest(data) {
  try {
    const response = yield call(getSummarisedProjectList, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const projectList = response.data.result
      yield put(actions.loadProjectListByStatusSuccess(projectList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* loadProjectAllocationTypeListRequest(data) {
  try {
    const response = yield call(getProjectAllocationTypeRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const projectList = response.data.result.result
      yield put(actions.loadProjectAllocationListSuccess(projectList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
  }
}


export function* loadProjectListbyStatusRequest(data) {
  try {
    const response = yield call(getProjectListbyStatus, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const projectList = response.data.result.result
      yield put(actions.loadProjectListSuccess(projectList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* loadProjectTypeListRequest(data) {
  try {
    const response = yield call(getProjectTypeRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const list = response.data.result.result
      yield put(actions.loadProjectTypeSuccess(list))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
  }
}

export function* loadProjectBillingTypeListRequest(data) {
  try {
    const response = yield call(getProjectBillingTypeRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const projectList = response.data.result.result
      yield put(actions.loadProjectBillingTypeSuccess(projectList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
  }
}

export function* sendImportProjectActivityRequest(data) {
  yield put(actions.loadProjectListError(null))
  try {
    const response = yield call(importProjectActivitiesRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.sendProjectActivitySuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadProjectListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadProjectListError(massage))
  }
}


export function* loadProjectTimeInputTypeListRequest(data) {
  try {
    const response = yield call(getProjectTimeInputTypeRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const list = response.data.result.result
      yield put(actions.loadProjectTimeInputTypeSuccess(list))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
  }
}
