import {
  LOAD_APP_BILLING_TERMS_REQUEST,
  LOAD_APP_BILLING_TERMS_SUCCESS,
  LOAD_APP_PACKAGE_REQUEST,
  LOAD_APP_PACKAGE_SUCCESS,
  LOAD_APP_UOM_REQUEST,
  LOAD_APP_UOM_SUCCESS,
  LOAD_COUNTRY_REQUEST,
  LOAD_COUNTRY_SUCCESS,
  LOAD_OBJECT_STATUS_REQUEST,
  LOAD_OBJECT_STATUS_SUCCESS,
  LOAD_PUBLIC_CALENDAR_REQUEST,
  LOAD_PUBLIC_CALENDAR_SUCCESS,
  LOAD_TENANT_REQUEST,
  LOAD_TENANT_SUCCESS,
  LOAD_TENANT_USER_REQUEST,
  LOAD_TENANT_USER_SUCCESS,
  LOAD_UOM_REQUEST,
  LOAD_UOM_SUCCESS,
  SAVE_COMMON_SEARCH_SUCCESS,
  SAVE_TENANT_USER_EXPIRE_STATUS,
  SAVE_USER_ACCESS
} from '../constant/constant';

export function loadObjectStatusRequest(data) {
  return {
    type: LOAD_OBJECT_STATUS_REQUEST,
    payload: data,
  }
};

export function loadObjectStatusSuccess(data) {
  return {
    type: LOAD_OBJECT_STATUS_SUCCESS,
    payload: data,
  }
};

export function loadUOMRequest() {
  return {
    type: LOAD_UOM_REQUEST,
  }
};

export function loadUOMSuccess(data) {
  return {
    type: LOAD_UOM_SUCCESS,
    payload: data,
  }
};

export function loadCountryRequest() {
  return {
    type: LOAD_COUNTRY_REQUEST,
  }
};

export function loadCountrySuccess(data) {
  return {
    type: LOAD_COUNTRY_SUCCESS,
    payload: data,
  }
};

export function loadTenantRequest(data) {
  return {
    type: LOAD_TENANT_REQUEST,
    payload: data,
  }
};

export function loadTenantSuccess(data) {
  return {
    type: LOAD_TENANT_SUCCESS,
    payload: data,
  }
};



export function loadTenantUserRequest(data) {
  return {
    type: LOAD_TENANT_USER_REQUEST,
    payload: data,
  }
};

export function loadTenantUserSuccess(data) {
  return {
    type: LOAD_TENANT_USER_SUCCESS,
    payload: data,
  }
};

export function saveUserAccess(data) {
  return {
    type: SAVE_USER_ACCESS,
    payload: data,
  }
};

export function saveCommonSearchSuccess(data) {
  return {
    type: SAVE_COMMON_SEARCH_SUCCESS,
    payload: data,
  }
};

export function saveTenantUserExpireStatus(data) {
  return {
    type: SAVE_TENANT_USER_EXPIRE_STATUS,
    payload: data,
  }
};

export function loadAppBillingTermsRequest(data) {
  return {
    type: LOAD_APP_BILLING_TERMS_REQUEST,
    payload: data,
  }
};

export function loadAppBillingTermsSuccess(data) {
  return {
    type: LOAD_APP_BILLING_TERMS_SUCCESS,
    payload: data,
  }
};

export function loadAppPackageRequest(data) {
  return {
    type: LOAD_APP_PACKAGE_REQUEST,
    payload: data,
  }
};


export function loadAppPackageSuccess(data) {
  return {
    type: LOAD_APP_PACKAGE_SUCCESS,
    payload: data,
  }
};


export function loadPublicCalendarRequest(data) {
  return {
    type: LOAD_PUBLIC_CALENDAR_REQUEST,
    payload: data,
  }
};

export function loadPublicCalendarSuccess(data) {
  return {
    type: LOAD_PUBLIC_CALENDAR_SUCCESS,
    payload: data,
  }
};

export function loadAppUOMRequest(data) {
  return {
    type: LOAD_APP_UOM_REQUEST,
    payload: data,
  }
};

export function loadAppUOMSuccess(data) {
  return {
    type: LOAD_APP_UOM_SUCCESS,
    payload: data,
  }
};